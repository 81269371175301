export interface IMovement {
  idMovement: string;
  idCompany: string;
  idBranch?: string;
  description: string;
  notes: string;
  amount: string;
  movementType: boolean;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
}

export class Movement {
  idMovement: string;
  idCompany: string;
  idBranch?: string;
  description: string;
  notes: string;
  amount: string;
  movementType: boolean;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IMovement) {
    this.idMovement = data.idMovement;
    this.idCompany = data.idCompany;
    this.idBranch = data.idBranch;
    this.description = data.description;
    this.notes = data.notes;
    this.amount = data.amount;
    this.movementType = data.movementType;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  get movementTypeDescription(): string {
    return this.movementType ? 'Ingreso' : 'Gasto';
  }

  get statusDescription(): string {
    let value: string = '';

    if(this.movementType) {

      if(this.status) {
        value = 'Cobrado';
      } else {
        value = 'Pendiente';
      }

    } else {

      if(this.status) {
        value = 'Pagado';
      } else {
        value = 'Pendiente';
      }

    }

    return value;
  }
}
