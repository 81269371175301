import { Component, Input, ViewChild } from '@angular/core';
import { Movement } from '../../models/movement.model';
import { NgbActiveModal, NgbCalendar, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataFormModel } from '../../models/data-form-model.model';
import { MovementService } from '../../services/movement.service';
import { UtilService } from '../../services/util.service';
import { FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-movement-modal',
  templateUrl: './movement-modal.component.html',
  styleUrl: './movement-modal.component.scss'
})
export class MovementModalComponent {

  @Input() idBranch: string = '';
  @Input() movement?: Movement;

  @ViewChild('dp') dp?: NgbDatepicker;

  public model?: NgbDateStruct;
	public date?: { year: number; month: number };

  public movementTypes: { value: boolean, label: string }[] = [
    {
      value: true,
      label: 'Ingreso'
    },
    {
      value: false,
      label: 'Egreso'
    }
  ];

  public statusDescription: { value: boolean, label: string }[] = [
    {
      value: false,
      label: 'Pendiente'
    },
    {
      value: true,
      label: 'Completado'
    }
  ];

  public dataForm: DataFormModel = {
    description: {
      value: '',
      type: 'text',
      label: 'Descripción del movimiento',
      requiredMessage: 'Por favor, ingrese una descripción del movimiento',
      required: true,
      validators: [Validators.required]
    },
    notes: {
      value: '',
      type: 'textarea',
      label: 'Notas',
      requiredMessage: '',
      required: false,
      validators: []
    },
    movementType: {
      value: null,
      type: 'select',
      idSelect: 'value',
      labelSelect: 'label',
      data: this.movementTypes,
      label: 'Tipo de movimiento',
      placeholder: 'Seleccione el tipo de movimiento',
      requiredMessage: 'Por favor, seleccione el tipo de movimiento',
      required: true,
      validators: [Validators.required]
    },
    status: {
      value: null,
      type: 'select',
      idSelect: 'value',
      labelSelect: 'label',
      data: this.statusDescription,
      label: 'Estado del movimiento',
      placeholder: 'Seleccione el estado del movimiento',
      requiredMessage: 'Por favor, seleccione el estado del movimiento',
      required: true,
      validators: [Validators.required]
    },
    amount: {
      value: '',
      type: 'text',
      label: 'Monto',
      requiredMessage: 'Por favor, ingrese el monto del movimiento',
      required: true,
      validators: [Validators.required]
    },
  };

  public formMovement!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;

  public imageBase64: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    public movementService: MovementService,
    public ngbCalendar: NgbCalendar,
    public utilService: UtilService,
  ) {
    

  }

  ngOnInit(): void {
    this.loadData();

    if(this.movement) {
      
      const momentDate = moment(this.movement.createdAt).add(
        -6,
        'hours'
      );

      
      this.model = {
        year: momentDate.year(),
        month: momentDate.month() + 1,
        day: parseInt(momentDate.format('D')),
      };

    }
  }

  get f() {
    return this.formMovement.controls;
  } 

  /*
    ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
    ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create() {

    this.formMovement.value.imageBase64 = this.imageBase64;

    if(this.imageBase64) {

      let fileType = 'image';

      if(this.imageBase64.includes('data:application/pdf')) {
        fileType = 'pdf';
      }

      this.formMovement.value.fileType = fileType;

    }

    const flag = await this.movementService.create(
      this.formMovement.value,
      this.idBranch,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    if(this.movement) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.movement!) {
          const standardKey = key as keyof typeof this.movement;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.movement[standardKey]!;
        }
      } 
    }
    
    this.formMovement = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗██╗     ███████╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗
  ██╔═══██╗████╗  ██║██╔════╝██║██║     ██╔════╝██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝
  ██║   ██║██╔██╗ ██║█████╗  ██║██║     █████╗  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗
  ██║   ██║██║╚██╗██║██╔══╝  ██║██║     ██╔══╝  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝
  ╚██████╔╝██║ ╚████║██║     ██║███████╗███████╗╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗
   ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION onFileChange
   * 
   * @param event 
   */
  onFileChange(event: any) {
    console.log(event);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file); // toBase64
    reader.onload = () => {
      this.imageBase64 = reader.result as string; // base64 Image src
      console.log(this.imageBase64);
      
    };
    
  }

  /*
    ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
    ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formMovement.value);
    
    this.submitted = true;

    if(this.formMovement.invalid) {
      return;
    }

    if(this.movement) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
    ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    
    let dateSelected = moment(this.model).add(-1, 'months');
    
    let createdAt = moment(this.movement?.createdAt).add(-6, 'hours');
    
    console.log(dateSelected);
    console.log(createdAt);
    
    let createdAtFinal = null;
    
    if(
      dateSelected.format('YYYY-MM-DD') === createdAt.format('YYYY-MM-DD')
    ) {
      console.log('Son iguales');
    } else {
      console.log('No son iguales');
      createdAtFinal = `${moment(this.model).add(-1, 'months').format('YYYY-MM-DD')} 13:00:00`;
      
      this.formMovement.value.createdAt = createdAtFinal;
    }
    
    // this.formMovement.value.imageBase64 = this.imageBase64;
    this.formMovement.value.idBranch = this.movement!.idBranch;
    this.formMovement.value.idMovement = this.movement!.idMovement;

    if(this.imageBase64) {

      let fileType = 'image';

      if(this.imageBase64.includes('data:application/pdf')) {
        fileType = 'pdf';
      }

      this.formMovement.value.fileType = fileType;

    }

    const flag = await this.movementService.update(
      this.formMovement.value
    );

    if(flag) {

      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }
  }

}
