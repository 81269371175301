<div class="modal-header">
  <h4 class="modal-title">
    @if (movement) {
      Editar movimiento {{ movement.idMovement }} 
    }

    @if (!movement) {
      Nuevo movimiento
    }
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  @if (!loading) {
    <form
      id="formMovement"
      [formGroup]="formMovement"
      (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <div class="row">

          <!-- @if(imageBase64 || (movement && movement.image && movement.image.fileType == 'image')) {
            <div class="col-12">
              <img [src]="imageBase64 ? imageBase64 : (movement && movement!.image) ? movement.image.urlFile : ''" width="100%">
            </div>
          }

          @if((movement && movement.image && movement.image.fileType == 'pdf')) {
            <div class="col-12 my-3">
              <a
                class="btn btn-outline-primary w-100"
                [href]="movement!.image!.urlFile"
                target="_blank">
                Ver documento
              </a>
            </div>
          } -->

          @for (key of utilService.objectKeys(dataForm); track key) {
            @if (
              dataForm[key].type != "select" && 
              dataForm[key].type != "switch" &&
              dataForm[key].type != "textarea" &&
              dataForm[key].type != "image"
            ) {
              <div class="col-12 form-group mb-4">
                <label [htmlFor]="key" class="form-label">
                  {{ dataForm[key].label }}
                </label>
                <input
                  [type]="dataForm[key].type"
                  class="form-control"
                  formControlName="{{ key }}"
                  [placeholder]="dataForm[key].placeholder ?? dataForm[key].label"
                  [ngClass]="{
                    'is-invalid': submitted && f[key].errors
                  }">
                  @if (submitted && f[key].errors && dataForm[key].required) {
                    <div class="invalid-feedback">
                      @if (f[key].errors!['required']) {
                        <div>{{ dataForm[key].requiredMessage }}</div>
                      }
                    </div>
                  }
              </div>
            }

            @if (dataForm[key].type === 'select') {
              <div class="mb-4">
                <div class="mb-1">
                  {{ dataForm[key].label }}
                </div>
                <ng-select
                  [items]="dataForm[key].data!"
                  [bindLabel]="dataForm[key].labelSelect!"
                  [bindValue]="dataForm[key].idSelect!"
                  formControlName="{{ key }}"
                  [placeholder]="dataForm[key].placeholder ?? ''"
                  (ngModelChange)="dataForm[key].onChange ? dataForm[key].onChange!($event) : null">
  
                </ng-select>
                @if (submitted && f[key].errors && dataForm[key].required) {
                  <div class="text-danger">
                    @if (f[key].errors!['required']) {
                      {{ dataForm[key].requiredMessage }}
                    }
                  </div>
                }
              </div>
            }

            @if (dataForm[key].type === 'switch') {
              <div class="col-12 mt-2 mb-4">
                <div class="row">
                  <div class="col-8">
                    {{ dataForm[key].label }}
                  </div>
                  <div class="col-4 text-end">
                    <p-inputSwitch
                      formControlName="{{ key }}">

                    </p-inputSwitch>
                  </div>
                </div>
              </div>
            }

            @if (dataForm[key].type === 'textarea') {
              <div class="col-12 form-group mb-4">
                <label [htmlFor]="key" class="form-label">
                  {{ dataForm[key].label }}
                </label>
                <textarea
                  class="form-control"
                  formControlName="{{ key }}"
                  [placeholder]="dataForm[key].placeholder ?? dataForm[key].label"
                  [ngClass]="{
                    'is-invalid': submitted && f[key].errors
                  }"></textarea>
                  @if (submitted && f[key].errors && dataForm[key].required) {
                    <div class="invalid-feedback">
                      @if (f[key].errors!['required']) {
                        <div>{{ dataForm[key].requiredMessage }}</div>
                      }
                    </div>
                  }
              </div>
            }

            @if(dataForm[key].type == 'image') {
              <div class="col-12 form-group mb-4">
                <label [htmlFor]="key" class="form-label">
                  {{ dataForm[key].label }}
                </label>
                <input 
                  class="form-control" 
                  type="file"
                  accept="image/*, application/pdf"
                  [id]="key"
                  (change)="onFileChange($event)">
              </div>
            }

          }

        </div>
      </div>
    </form>

    @if(movement) {

      <div class="container-fluid">
        <div class="row">
  
          <div class="col-12 text-ceter">
  
            <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next" />
  
          </div>
  
        </div>
      </div>

    }
  }
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formMovement"
      class="btn btn-primary w-100">
      {{ movement ? 'Actualizar' : 'Registrar' }}
    </button>
  </div>
</div>